import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import SEO from "../../components/seo";
import Layout from "../../components/layout";
import styles from "../../scss/layouts/contact_success.module.scss";
import cta from "../../scss/elements/buttons.module.scss";


export default () => {

    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                companyTelephoneKnaresborough
              }
            }
          }
        `
      );
  return (
    <>
    <Layout>
    <SEO
        title="Success"
      />
        <div className={styles.success}>
          <div className={styles.success__inner}>
            <p className={styles.bold}>Thank you for your enquiry.</p>
            <p>One of our team will get back to you shortly. In the meantime, if you have any questions, please don't hesitate to get in touch by calling our head office on <a href={"tel:" +data.site.siteMetadata.companyTelephoneKnaresborough} title={"Call: " +data.site.siteMetadata.companyTelephoneKnaresborough}>{data.site.siteMetadata.companyTelephoneKnaresborough}</a>.</p>
            <div className={styles.success__button}>
                <a className={cta.button} href="/">Back To Homepage</a>
            </div>
          </div>
        </div>
    </Layout>
    </>
  )
}